<template>
	<div id="tu-book-course">
		<el-dialog
			v-model="dialogVisible"
			:title="$t('book-course')"
			width="45%"
			destroy-on-close
			:show-close="false">
			<div class="warp" v-if="!loading">
				<el-form ref="elForm" :model="form" :rules="rules" label-position="top">
					<div class="base-info">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item :label="$t('select-student')" prop="uid">
									<el-select v-if="Object.keys(study_list).length > 0" v-model="form.profile_id" size="medium" 
										clearable filterable :placeholder="$t('select-student')">
										<el-option v-for="item, profile_id in study_list" :label="item.nickname" :value="profile_id"></el-option>
									</el-select>
									<el-select v-else v-model="form.profile_id" size="medium" clearable filterable :placeholder="$t('select-student')">
										<el-option v-for="item, i in studentList" :label="item.nickname" :value="item.profile_id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="$t('what-subject')" prop="subject_id">
									<el-select v-model="form.subject_id" size="medium" clearable filterable :placeholder="$t('what-subject')">
										<template v-for="item, index in subjectData.subject">
											<el-option v-for="it, i in item.children" :label="item.name + '(' + it.name + ')'" :value="it.id"></el-option>
										</template>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="$t('how-many-classes')" prop="num">
									<el-select v-model="form.num" size="medium" :placeholder="$t('how-many-classes')" @change="manyClasses">
										<el-option v-for="it, index in 8" :key="index" :label="it" :value="it"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
					<div class="base-course" v-for="item, index in form.subscribe" :key="index">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item :label="$t('date') + '(' + zoneName + ')'" prop="day">
									<el-date-picker 
										v-model="item.day" 
										:format="dayShowFormat"
										value-format="YYYY-MM-DD" 
										size="medium" 
										:editable="false" 
										type="date" 
										:placeholder="$t('date')"
										@change="changeDateDayData(index)"></el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="$t('date-time')" prop="time">
									<el-time-picker
										v-model="item.time"
										size="medium"
										format="HH:mm"
										value-format="HH:mm"
										:editable="false"
										:placeholder="$t('date-time')"
										@change="changeDateTimeData(index)">
									</el-time-picker>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="$t('date-time') + '(' + zoneOtherName + ')'">
									<el-input v-model="item.other_time" size="medium" disabled :placeholder="$t('date-time')" />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item :label="$t('duration')" prop="duration">
									<el-time-select
									    v-model="item.duration"
									    start="00:30"
									    step="00:15"
									    end="02:30"
									    :placeholder="$t('duration')"
									  >
									</el-time-select>
								</el-form-item>
							</el-col>
							<el-col :span="16">
								<el-form-item :label="$t('hourly-rate') + ' (Default ' + userInfo.rate + ')'" prop="fee">
									<el-input v-model="item.fee" @blur="changePriceData(index)" size="medium" :placeholder="$t('hourly-rate')" />
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</el-form>
			</div>
			
			<template #footer>
				<span class="dialog-footer">
					<!-- <el-button @click="handleAddAnother">ADD ANOTHER</el-button> -->
					<el-button @click="handleClose">{{ $t('cancel') }}</el-button>
					<el-button type="primary" @click="handleConfirm">{{ $t('create-booking') }}</el-button>
				</span>
			</template>
	  	</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CourseApi from '@/api/course'
import TimeControl from '@/utils/time-control'

export default {
	name: 'BookCourse',
	props: {

	},
	data() {
		return {
			dialogVisible: false,
			loading: true,
			dayShowFormat: "DD/MM/YYYY",
			form: {
				uid: '',
				profile_id: '',
				subject_id: '',
				num: 1,
				subscribe: [
					{day: "", time: "", duration: "01:00", fee: "", other_time: ""},
				]
			},
			rules: {},
			study_list: [],
			student: [],
			subject: []
		}
	},
	created() {
		this.dayShowFormat = TimeControl.TimeFormat("YMD")
	},
	watch: {
		'form.profile_id': {
			handler: function(val, oldval) {
				for(let i in this.studentList) {
					if(this.studentList[i].profile_id == val) {
						this.form.uid = this.studentList[i].uid;
						break;
					}
				}
			},
			deep: true
		},
		'form.subscribe': {
			handler: function(val, oldval) {
				// let zone = TimeControl.zone();
				for(let i in this.form.subscribe) {
					let day = this.form.subscribe[i].day || "";
					let time = this.form.subscribe[i].time || "";
					if(!!day && !!time) {
						let timestamp = TimeControl.getUnixTime(day + " " + time);
						// if(zone == 1) {
						// 	this.form.subscribe[i].other_time = TimeControl.getLondonTime(timestamp)
						// } else {
						// 	this.form.subscribe[i].other_time = TimeControl.getChinaTime(timestamp)
						// }
						this.form.subscribe[i].other_time = TimeControl.getChinaTime(timestamp)
					}
				}
			},
			deep: true
		}
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType', 'subjectData', 'studentList'
		]),
		zoneName() {
			return TimeControl.zoneName();
		},
		zoneOtherName() {
			// let zone = TimeControl.zone();
			// if(zone == 1) {
			// 	return "EN"
			// }
			return "CH"
		}
	},
	methods: {
		...mapActions({
			GetInfo: 'GetInfo', GetMyStudent: 'GetMyStudent', GetSubjectData: 'GetSubjectData'
		}),
		// 打开预约课程弹框
		open(row) {
			this.form = {
				uid: '',
				profile_id: '',
				subject_id: '',
				num: 1,
				subscribe: [
					{day: "", time: "", duration: "01:00", fee: ""},
				]
			};

			// if(!this.$store.state.student.student_list) {
			// 	this.$store.dispatch('GetMyStudentList')
			// }
			// if(!this.subjectData) {
			// 	this.$store.dispatch('GetSubjectData')
			// }

			if(!!row) {
				this.form.uid = row.uid || ''
				this.form.profile_id = row.profile_id || ''
				this.form.subject_id = row.subject_id || ''

				if(!!row.study_list) {
					this.study_list = row.study_list || {};
				}
			}
			this.loading = true;
			this.$nextTick(() => {
				this.loading = false;
			})
			this.dialogVisible = true;
		},
		close() {
			this.$emit('change');
			this.dialogVisible = false;
		},
		// 需要几节课
		manyClasses(val) {
			let subscribe = []
			for(let i in this.form.subscribe) {
				if(val > i) {
					subscribe.push(this.form.subscribe[i])
				}
			}
			if(subscribe.length < val) {
				for(let k=subscribe.length; k < val; k++ ) {
					subscribe.push({day: "", time: "", duration: "01:00", fee: ""})
				}
			}
			this.form.subscribe = subscribe
		},
		// 添加一个预约课程
		handleAddAnother() {

		},
		// 关闭是判断，是否提示
		handleClose() {

			this.close();
		},
		// 确认保存预约课程
		handleConfirm() {
			let form = this.form;
			for(let i in form.subscribe) {
				form.subscribe[i].timestamp = TimeControl.getUnixTime(form.subscribe[i].day + ' ' + form.subscribe[i].time);
			}
			if(!!!form.uid || !!!form.profile_id) {
				this.$message.warning(this.$t('course booking selection of students'))
				return ;
			}
			if(!!!form.subject_id) {
				this.$message.warning(this.$t('course booking selection of subject'))
				return ;
			}
			if(form.subscribe.length <= 0) {
				this.$message.warning(this.$t('course booking selection of data'))
				return ;
			}
			CourseApi.order(form).then(res => {
				this.$message.success(this.$t('course booking success'))
				this.close()
			})
		},
		changeDateDayData(index) {
			if(index != 0) {
				return;
			}
			let first = this.form.subscribe[index];
			let form = this.form;
			for(let i in form.subscribe) {
				if(!!!form.subscribe[i].day) {
					form.subscribe[i].day = TimeControl.getFormatTimeAdd(first.day, 86400*i, "YYYY-MM-DD");
				}
			}
		},
		changeDateTimeData(index) {
			if(index != 0) {
				return;
			}
			let first = this.form.subscribe[index];
			let form = this.form;
			for(let i in form.subscribe) {
				if(!!!form.subscribe[i].time) {
					form.subscribe[i].time = first.time;
				}
			}
		},
		changePriceData(index) {
			if(index != 0) {
				return;
			}
			let first = this.form.subscribe[index];
			let form = this.form;
			for(let i in form.subscribe) {
				if(!!!form.subscribe[i].fee) {
					form.subscribe[i].fee = first.fee;
				}
			}
		},
	}
}
</script>
<style lang="less">
#tu-book-course {
	.el-dialog {
		.el-dialog__body {
			padding: 0px 20px;
		}

		.el-form-item {
			.el-form-item__label {
				padding: 0;
				font-weight: 600;
				line-height: 2;
			}

			.el-form-item__content {
				.el-select {
					width: 100%;
				}

				.el-input {
					width: 100%;
				}
			}
		}

		.warp {
			.base-info {

			}

			.base-course {
				border: 1px solid #DDD;
				padding: 10px;
				margin-bottom: 5px;

				.el-form-item {
					margin-bottom: 5px;

					.el-form-item__label {
						font-weight: 500;
					}
				}
			}
		}
	}
}
</style>